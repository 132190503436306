import * as React from 'react';
import HeaderComponent from '../../components/header/HeaderComponent';
import { GlobalStyle } from '../../styles/global';

const ContratePage = () => {
  return (
    <main>
      <GlobalStyle />
      <title>
        Horizonte - Capacitação e geração de emprego no mercado de tecnologia.
      </title>
      <HeaderComponent />
      <section>
        <h1>contrate</h1>
      </section>
    </main>
  );
};

export default ContratePage;
